@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: grenette;
  font-weight: 300;
  font-type: normal;
  src: url('/fonts/grenette-light-pro.woff2') format('woff2'),
    url('/fonts/grenette-light-pro.woff') format('woff');
}

@font-face {
  font-family: 'centra';
  src: url('/fonts/CentraNo2-Book.eot');
  src: url('/fonts/CentraNo2-Book.eot#iefix') format('embedded-opentype'),
    url('/fonts/CentraNo2-Book.woff2') format('woff2'),
    url('/fonts/CentraNo2-Book.woff') format('woff'),
    url('/fonts/CentraNo2-Book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'centra';
  src: url('/fonts/CentraNo2-BookItalic.eot');
  src: url('/fonts/CentraNo2-BookItalic.eot#iefix') format('embedded-opentype'),
    url('/fonts/CentraNo2-BookItalic.woff2') format('woff2'),
    url('/fonts/CentraNo2-BookItalic.woff') format('woff'),
    url('/fonts/CentraNo2-BookItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'centra';
  src: url('/fonts/CentraNo2-Medium.eot');
  src: url('/fonts/CentraNo2-Medium.eot#iefix') format('embedded-opentype'),
    url('/fonts/CentraNo2-Medium.woff2') format('woff2'),
    url('/fonts/CentraNo2-Medium.woff') format('woff'),
    url('/fonts/CentraNo2-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'centra';
  src: url('/fonts/CentraNo2-MediumItalic.eot');
  src: url('/fonts/CentraNo2-MediumItalic.eot#iefix')
      format('embedded-opentype'),
    url('/fonts/CentraNo2-MediumItalic.woff2') format('woff2'),
    url('/fonts/CentraNo2-MediumItalic.woff') format('woff'),
    url('/fonts/CentraNo2-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
